.loading {
  width: 100%;
  .spinner {
    position: relative;
    top: 50%;
    text-align: center;

    > div {
      width: 18px;
      height: 18px;
      background-color: #063b72;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bounce-delay 1.4s infinite ease-in-out both;
      animation: sk-bounce-delay 1.4s infinite ease-in-out both;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bounce-delay {
      0%, 80%, 100% {
        -webkit-transform: scale(0)
      }
      40% {
        -webkit-transform: scale(1.0)
      }
    }

    @keyframes sk-bounce-delay {
      0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
    }
  }

  @media (min-height: 480px) {
    height: 300px;
  }

  @media (min-height: 600px) {
    height: 600px;
  }

  @media (min-height: 720px) {
    height: 600px;
  }

  @media (min-height: 800px) {
    height: 700px;
  }

  @media (min-height: 900px) {
    height: 800px;
  }
}









