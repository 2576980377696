/* common classes */
.flex {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.fac {
  -webkit-align-items: center;
  align-items: center;
}

.fae {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.fas {
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.fjb {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.fja {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.fjc {
  -webkit-justify-content: center;
  justify-content: center;
}

.fje {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.fdc {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.fdc-reverse {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.fdr {
  -webkit-flex-direction: row;
  -ms-flex-direction: row; 
  flex-direction: row;
}

.fdr-reverse {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.f-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.trans {
  transition: all .3s;
}

.ttu {
  text-transform: uppercase;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.transparent {
  opacity: 0;
}

.hidden {
  display: none;
}

.no-drag {
  pointer-events: none;
}

.no-border {
  border: none !important;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fz {
  &--11 {
    font-size: 11px;
  }
  &--14 {
    font-size: 14px;
  }
  &--16 {
    font-size: 16px;
  }
  &--18 {
    font-size: 18px;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
}