// Colors
$white: #FFFFFF;
$blue: #063B72;
$grey: #e3e3e3;
$light-grey: #DDDDDD;
$red: #e60d0d;
$light-green: #40A819;
$dark-green: #4A9402;

// Text colors
$base-text-color: #37393A;
$optional-text-color: #787E7F;
// Border colors
$border-color: #EEEEEE;

%trans {
  transition: all .3s;
}

%ff-ar {
  font-family: 'Apercu', sans-serif;
  font-weight: normal;
  font-style: normal;
}

%ff-am {
  font-family: 'Apercu', sans-serif;
  font-weight: 500;
  font-style: normal;
}

%ff-arp {
  font-family: 'Apercu Pro', sans-serif;
  font-weight: normal;
  font-style: normal;
}

%ff-amp {
  font-family: 'Apercu', sans-serif;
  font-weight: 700;
  font-style: normal;
}