@font-face {
  font-family: "Apercu";
  src: local("Apercu Medium Italic"), local("Apercu-MediumItalic"), url("../fonts/Apercu/Apercu-MediumItalic.woff2") format("woff2"), url("../fonts/Apercu/Apercu-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu Light Italic"), local("Apercu-LightItalic"), url("../fonts/Apercu/Apercu-LightItalic.woff2") format("woff2"), url("../fonts/Apercu/Apercu-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Apercu Mono";
  src: local("Apercu Mono"), local("Apercu-Mono"), url("../fonts/Apercu/Apercu-Mono.woff2") format("woff2"), url("../fonts/Apercu/Apercu-Mono.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu Medium"), local("Apercu-Medium"), url("../fonts/Apercu/Apercu-Medium.woff2") format("woff2"), url("../fonts/Apercu/Apercu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu Bold"), local("Apercu-Bold"), url("../fonts/Apercu/Apercu-Bold.woff2") format("woff2"), url("../fonts/Apercu/Apercu-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu Bold Italic"), local("Apercu-BoldItalic"), url("../fonts/Apercu/Apercu-BoldItalic.woff2") format("woff2"), url("../fonts/Apercu/Apercu-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu"), local("Apercu-Regular"), url("../fonts/Apercu/Apercu-Regular.woff2") format("woff2"), url("../fonts/Apercu/Apercu-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu Light"), local("Apercu-Light"), url("../fonts/Apercu/Apercu-Light.woff2") format("woff2"), url("../fonts/Apercu/Apercu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu Italic"), local("Apercu-Italic"), url("../fonts/Apercu/Apercu-Italic.woff2") format("woff2"), url("../fonts/Apercu/Apercu-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}