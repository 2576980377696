html, body {
  height: 100%;
}

body {
  background-color: white;
}

.Toastify {  
  .toast-container {
    padding: 0;
    top: 0;
    width: 100%;
  }
}